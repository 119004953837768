import api, { xspocApiInstance } from "../../services/api.ts";

export interface CustomerItem {
  id: string;
  createdBy: string;
  createdOnDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  cnX_CustomerId: number;
  cnX_CustomerName: string;
  name: string;
  isActive: boolean;
  b2Cdomainname: string;
}

export interface CustomerApiResponse {
  values: CustomerItem[];
  id: string;
  dateCreated: string;
}

export interface CustomerSearchResponse {
  id: string;
  name: string;
}
// Fetch customer data from API
const fetchCustomerDataAsync = async (): Promise<CustomerItem[]> => {
  try {
    const response = await xspocApiInstance.get<CustomerApiResponse[]>(
      "/Customer",
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && Array.isArray(response.data.values)) {
      return response.data.values;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching customer data", error);
    return [];
  }
};

// Fetch customer data by ID
const fetchCustomerDataByIdAsync = async (
  id: string
): Promise<CustomerItem | null> => {
  try {
    const response = await xspocApiInstance.get<CustomerApiResponse>(
      `/Customer/${id}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (
      response &&
      response.data &&
      Array.isArray(response.data.values) &&
      response.data.values.length > 0
    ) {
      return response.data.values[0];
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error fetching customer data", error);
    return null;
  }
};

// Search customer data by name from API
const searchCustomerByNameAsync = async (
  name: string
): Promise<CustomerSearchResponse[]> => {
  try {
    const response = await api.get<CustomerSearchResponse[]>(
      `/CNXCustomer/search/${name}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error searching customer data by name", error);
    return [];
  }
};

// Add new customer
const addNewCustomerAsync = async (
  newCustomer: CustomerItem
): Promise<CustomerItem | null> => {
  try {
    const response = await xspocApiInstance.post<CustomerItem>(
      "/Customer",
      newCustomer,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error adding new customer", error);
    throw error;
  }
};

// Edit existing customer
const editCustomerAsync = async (
  customerId: string,
  updatedCustomer: CustomerItem
): Promise<CustomerItem | null> => {
  try {
    const response = await xspocApiInstance.put<CustomerItem>(
      `/Customer?id=${customerId}`,
      updatedCustomer,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error editing customer", error);
    throw error;
  }
};

export {
  fetchCustomerDataAsync,
  fetchCustomerDataByIdAsync,
  searchCustomerByNameAsync,
  addNewCustomerAsync,
  editCustomerAsync,
};
