const apiMap = {
  api: process.env.REACT_APP_WEB_API_BASE_URL,
  xspocapi: process.env.REACT_APP_XSPOC_API_BASE_URL,
};
 
export const getAPIBaseURL = (id = "api") => {
  const lowercasedId = id.toLowerCase();
  const baseUrl = apiMap[lowercasedId];
 
  if (baseUrl) {
    return `${baseUrl}/${lowercasedId}`;
  }
 
  return `/${lowercasedId}`;
};
 
export const getXSPOCAPIBaseURL = (id = "xspocapi") => {
  const lowercasedId = id.toLowerCase();
  const baseUrl = apiMap[lowercasedId];
 
  if (baseUrl) {
    return `${baseUrl}/api`;
  }
 
  return `/${lowercasedId}`;
};