import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./customTable.scss";
import Loader from "../../common/loader/loader.tsx";
import Tooltip from "../tooltip/tooltip.tsx";
import { ReactComponent as Download } from "../../images/download-icon.svg";
import { downloadCSV, generateCSVContent } from "../csvUtils/csvUtils.tsx";

interface CustomTableProps {
  data: any[];
  columns: any[];
  handleEdit: (row: any) => void;
  handleDelete: (id: number) => void;
  searchQuery: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  headerComponent?: React.ReactNode;
  currentPageIndex: number;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  csvFileName?: string;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data = [],
  columns = [],
  handleEdit,
  handleDelete,
  searchQuery = "",
  onSearch,
  headerComponent,
  currentPageIndex,
  setCurrentPageIndex,
  isLoading,
  csvFileName = "data.csv",
}) => {
  const [pageSize, setPageSize] = useState<number>(8);

  useEffect(() => {
    const calculatePageSize = () => {
      const tableHeight = window.innerHeight - 300;
      const rowHeight = 50;
      const newPageSize = Math.floor(tableHeight / rowHeight);
      setPageSize(Math.max(newPageSize, 1));
    };

    calculatePageSize();
    window.addEventListener("resize", calculatePageSize);

    return () => window.removeEventListener("resize", calculatePageSize);
  }, []);

  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    return data.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, searchQuery]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex: tablePageIndex },
    prepareRow,
    setPageSize: setTablePageSize,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: currentPageIndex, pageSize: pageSize },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setTablePageSize(pageSize);
    setCurrentPageIndex(tablePageIndex);
  }, [pageSize, tablePageIndex, setTablePageSize, setCurrentPageIndex]);

  const handleDownloadClick = () => {
    // Use columns for CSV columns and data for CSV rows
    const columnsToExport = columns.slice(0, -1); // Exclude the last column
    const rowsToExport = data.map(row => {
      const processedRow = { ...row };
      for (const key in processedRow) {
        if (processedRow[key] === true) {
          processedRow[key] = 'Yes';
        } else if (processedRow[key] === false) {
          processedRow[key] = 'No';
        }
      }
      return processedRow;
    });

    // Generate CSV content and download the CSV file
    const csvContent = generateCSVContent(columnsToExport, rowsToExport);
    downloadCSV(csvContent, csvFileName);
  };

  return (
    <>
      <div className="table-header-items">
        <div className="header-actions">
          <Tooltip content="Download CSV file" direction="top">
            <span className="download-icon" onClick={handleDownloadClick}>
              <Download />
            </span>
          </Tooltip>
          {headerComponent && (
            <div className="header-component">{headerComponent}</div>
          )}
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={onSearch}
          placeholder="Search"
          className="search-bar"
        />
      </div>
      <div className="table-container">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerGroupKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...restColumnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...restColumnProps}
                        className="table-header-item th"
                      >
                        {column.render("Header")}
                        <span className="sort-image">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "↓"
                              : "↑"
                            : "↕"}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <Loader />
                </td>
              </tr>
            ) : (
              page.map((row) => {
                prepareRow(row);
                const { key: rowKey, ...restRowProps } = row.getRowProps();
                return (
                  <tr key={rowKey} {...restRowProps}>
                    {row.cells.map((cell) => {
                      const { key: cellKey, ...restCellProps } =
                        cell.getCellProps();
                      return (
                        <td key={cellKey} {...restCellProps}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span className="pagination-info">
            Page {tablePageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className="pagination-button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomTable;
