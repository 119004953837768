import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./components/userDetails/userSlice.ts";
import customerReducer from "./components/customerDetails/customerSlice.ts";
import assetReducer from "./components/assetDetails/assetSlice.ts";

const store = configureStore({
  reducer: {
    users: userReducer,
    customers: customerReducer,
    assets: assetReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
