export const generateCSVContent = (columns: any[], rows: any[]) => {
  let csvContent = "";

  // Adding header row
  csvContent += columns.map((column) => `"${column.Header}"`).join(",") + "\n";

  // Adding data rows
  rows.forEach((row) => {
    const rowData = columns
      .map((column) => {
        const value = row[column.accessor] || "";
        const escapedValue =
          typeof value === "string" ? `"${value.replace(/"/g, '""')}"` : value;
        return escapedValue;
      })
      .join(",");
    csvContent += `${rowData}\n`;
  });

  return csvContent;
};

export const downloadCSV = (content: string, fileName: string) => {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
