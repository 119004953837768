import api, { xspocApiInstance } from "../../services/api.ts";

export interface PocType {
  id: string;
  legacyId: number;
  lookupType: string;
  description: string;
}

export interface AssetItem {
  id: string;
  customerId: string | null;
  customerName: string;
  createdOnDate: string;
  lastModifiedDate: string;
  cnX_WellName: string;
  name: string;
  cnX_SiteId: number | null;
  cnX_WellId: number | null;
  envWellId: number | null;
  apI_UWI: string | null;
  pocType: PocType;
  portId: string;
  nodeAddress: string;
  isEnabled: boolean;
}

export interface AssetApiResponse {
  values: AssetItem[];
  id: string;
  dateCreated: string;
}

export interface ControllerPort {
  id: string;
  serverName: string;
  portID: number;
  description: string;
}

export interface ControllerType {
  id: string;
  lookupType: string;
  pocType: string;
  description: string;
}

export interface AssetSearchResponse {
  id: string;
  wellName: string;
  siteID: string;
  envWellID: string;
  apI_UWI: string;
}

// Fetch asset data from API
const fetchAssetDataAsync = async (): Promise<AssetItem[]> => {
  try {
    const response = await xspocApiInstance.get<AssetApiResponse>("/Asset", {
      headers: {
        "content-type": "application/json",
      },
    });

    if (response && response.data && Array.isArray(response.data.values)) {
      return response.data.values;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching asset data", error);
    return [];
  }
};

// Fetch asset data by ID
const fetchAssetDataByIdAsync = async (
  id: string
): Promise<AssetItem | null> => {
  try {
    const response = await xspocApiInstance.get<AssetApiResponse>(
      `/Asset/${id}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (
      response &&
      response.data &&
      Array.isArray(response.data.values) &&
      response.data.values.length > 0
    ) {
      return response.data.values[0];
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error fetching asset data by id", error);
    return null;
  }
};

// Search customer data by name from API
const searchAssetByNameAsync = async (
  name: string
): Promise<AssetSearchResponse[]> => {
  try {
    const response = await api.get<AssetSearchResponse[]>(
      `/CNXWell/search/${name}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error searching asset data by name", error);
    return [];
  }
};

// Fetch asset controller port data from API
const fetchAssetControllerPortDataAsync = async (): Promise<
  ControllerPort[]
> => {
  try {
    const response = await xspocApiInstance.get<ControllerPort[]>("/Port", {
      headers: {
        "content-type": "application/json",
      },
    });

    if (response && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching asset data", error);
    return [];
  }
};

// Fetch asset controller type data from API
const fetchAssetControllerTypeDataAsync = async (): Promise<
  ControllerType[]
> => {
  try {
    const response = await xspocApiInstance.get<ControllerType[]>("/Lookup", {
      headers: {
        "content-type": "application/json",
      },
    });

    if (response && Array.isArray(response.data)) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching asset data", error);
    return [];
  }
};

// Add new Asset
const addNewAssetAsync = async (
  newAsset: AssetItem
): Promise<AssetItem | null> => {
  try {
    const response = await xspocApiInstance.post<AssetItem>(
      "/Asset",
      newAsset,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error adding new Asset", error);
    throw error;
  }
};

// Edit existing User
const editAssetAsync = async (
  AssetId: string,
  updatedAsset: AssetItem
): Promise<AssetItem | null> => {
  try {
    const response = await xspocApiInstance.put<AssetItem>(
      `/Asset/${AssetId}`,
      updatedAsset,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error editing Asset", error);
    throw error;
  }
};

export {
  fetchAssetDataAsync,
  searchAssetByNameAsync,
  fetchAssetDataByIdAsync,
  addNewAssetAsync,
  editAssetAsync,
  fetchAssetControllerPortDataAsync,
  fetchAssetControllerTypeDataAsync,
};
