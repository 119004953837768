interface IGrantTypeConstant {
  form: string;
  refreshToken: string;
}

const GrantTypeConstant: IGrantTypeConstant = {
  form: 'External',
  refreshToken: 'refresh_token',
};

export default GrantTypeConstant;
