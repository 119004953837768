import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ChevronUp } from "../../../../images/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../images/chevron-down.svg";
import "./groupDropdown.scss";
import { Group } from "../../userService";

interface GroupDropdownProps {
  items: Group[];
  defaultOption?: string;
  onChange?: (item: Group) => void;
}

const GroupDropdown: React.FC<GroupDropdownProps> = ({
  items = [],
  defaultOption = "Choose one",
  onChange,
}) => {
  const [activeGroups, setActiveGroups] = useState<Group[]>([]);
  const [selectedItem, setSelectedItem] = useState<Group | null>(null);
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = (group: Group) => {
    const index = activeGroups.findIndex((g) => g === group);
    if (index !== -1) {
      setActiveGroups(activeGroups.slice(0, index));
      setSelectedItem(null);
    } else {
      setActiveGroups([...activeGroups, group]);
      setSelectedItem({ name: group.groupName, id: group.groupName });
      if (onChange) {
        onChange({ name: group.groupName, id: group.groupName });
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderGroups = (groups: Group[], level: number = 0) => {
    if (!Array.isArray(groups) || groups.length === 0) {
      return <div>No groups available</div>;
    }

    return groups.map((group, index) => (
      <div key={index} className={`group level-${level}`}>
        <div
          className={`item ${
            selectedItem?.name === group.groupName ? "item-selected" : ""
          } group-name`}
          onClick={() => handleClick(group)}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {group.groupName}
          <span>
            {activeGroups.includes(group) ? <ChevronUp /> : <ChevronDown />}
          </span>
        </div>
        {activeGroups.includes(group) && group.childGroups?.length > 0 && (
          <div className="nested-groups">
            {renderGroups(group.childGroups, level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div ref={dropdownRef} className="dropdown">
      <div onClick={() => setIsActive(!isActive)} className="groupDropdown-btn">
        <span className="groupDropdown-input">
          {selectedItem ? selectedItem.name : defaultOption}
        </span>
        <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      <div
        className="dropdown-content"
        style={{ display: isActive ? "block" : "none", width: "90%" }}
      >
        {items.length > 0 ? (
          renderGroups(items)
        ) : (
          <div>No groups available</div>
        )}
      </div>
    </div>
  );
};

export default GroupDropdown;
