import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCustomerDataAsync,
  fetchCustomerDataByIdAsync,
  searchCustomerByNameAsync,
  CustomerSearchResponse,
  CustomerItem,
  addNewCustomerAsync,
  editCustomerAsync,
} from "./customerService.ts";

interface CustomerState {
  customer: CustomerItem[];
  searchResults: CustomerSearchResponse[];
  customerToEdit: CustomerItem | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  customerLoading: boolean;
  customerSearchLoading: boolean;
  customerSaveLoading: boolean;
  customerDeleteLoading: boolean;
  error: string | null;
}

const initialState: CustomerState = {
  customer: [],
  searchResults: [],
  customerToEdit: null,
  status: "idle",
  customerLoading: false,
  customerSearchLoading: false,
  customerSaveLoading: false,
  customerDeleteLoading: false,
  error: null,
};

// Async thunk for loading customers
export const loadCustomer = createAsyncThunk(
  "customer/fetchCustomerDataAsync",
  async () => {
    const response = await fetchCustomerDataAsync();
    return response;
  }
);

// Async thunk for fetching a customer by ID
export const fetchCustomerById = createAsyncThunk(
  "customer/fetchCustomerDataByIdAsync",
  async (id: string) => {
    const response = await fetchCustomerDataByIdAsync(id);
    return response;
  }
);

// Async thunk for searching customers by name
export const searchCustomerByName = createAsyncThunk(
  "customer/searchCustomerByNameAsync",
  async (name: string) => {
    const response = await searchCustomerByNameAsync(name);
    return response;
  }
);

// Async thunk to add new customer
export const addCustomer = createAsyncThunk(
  "customer/addNewCustomerAsync",
  async (newCustomer: CustomerItem, thunkAPI) => {
    try {
      const response = await addNewCustomerAsync(newCustomer);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to edit customer
export const editCustomer = createAsyncThunk(
  "customer/editCustomerAsync",
  async (
    payload: { customerId: string; updatedCustomer: CustomerItem },
    thunkAPI
  ) => {
    const { customerId, updatedCustomer } = payload;
    try {
      const finalUpdatedCustomer = {
        ...updatedCustomer,
      };

      const response = await editCustomerAsync(
        customerId,
        finalUpdatedCustomer
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomerAsync",
  async (customerId: string, thunkAPI) => {
    try {
      const customer = await fetchCustomerDataByIdAsync(customerId);
      const updatedCustomer = {
        ...customer,
        isActive: false,
        lastModifiedDate: customer?.lastModifiedDate
          ? customer.lastModifiedDate
          : new Date().toISOString(),
      };

      // Call the editCustomerAsync to update the customer
      const response = await editCustomerAsync(customerId, updatedCustomer);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetCustomerToEdit(state) {
      state.customerToEdit = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomer.pending, (state) => {
        state.customerLoading = true;
      })
      .addCase(loadCustomer.fulfilled, (state, action) => {
        state.customerLoading = false;
        state.customer = action.payload;
      })
      .addCase(loadCustomer.rejected, (state, action) => {
        state.customerLoading = false;
        state.error = action.error.message || "Failed to load Customer";
      })
      .addCase(fetchCustomerById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customerToEdit = action.payload;
      })
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch customer by ID";
      })
      .addCase(searchCustomerByName.pending, (state) => {
        state.customerSearchLoading = true;
      })
      .addCase(searchCustomerByName.fulfilled, (state, action) => {
        state.customerSearchLoading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchCustomerByName.rejected, (state, action) => {
        state.customerSearchLoading = false;
        state.error = action.error.message || "Failed to search Customer";
      })
      .addCase(addCustomer.pending, (state) => {
        state.status = "loading";
        state.customerSaveLoading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customerSaveLoading = false;
        if (action.payload) {
          state.customer.push(action.payload);
        }
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.customerSaveLoading = false;
        state.error = action.error.message || "Failed to add new Customer";
      })
      .addCase(editCustomer.pending, (state) => {
        state.status = "loading";
        state.customerSaveLoading = true;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customerSaveLoading = false;
        if (action.payload) {
          const updatedCustomer = action.payload;
          const index = state.customer.findIndex(
            (customer) => customer.id === updatedCustomer.id
          );
          if (index !== -1) {
            state.customer[index] = updatedCustomer;
          } else {
            state.customer.push(updatedCustomer);
          }
        }
      })
      .addCase(editCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.customerSaveLoading = false;
        state.error = action.error.message || "Failed to edit Customer";
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.status = "loading";
        state.customerDeleteLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customerDeleteLoading = false;
        if (action.payload) {
          const updatedCustomer = action.payload;
          const index = state.customer.findIndex(
            (customer) => customer.id === updatedCustomer.id
          );
          if (index !== -1) {
            state.customer[index] = updatedCustomer;
          }
        }
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.customerDeleteLoading = false;
        state.error = action.error.message || "Failed to delete Customer";
      });
  },
});

export const { resetCustomerToEdit } = CustomerSlice.actions;

export default CustomerSlice.reducer;
