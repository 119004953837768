import React from "react";

export const IndexPage = () => {
  return (
    <div className="page-layout">
      <div className="main-header">
        <div className="header-name">Onboarding</div>
      </div>
    </div>
  );
};
