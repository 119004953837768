import React from "react";
import "./pageLayout.scss";
import { Outlet } from "react-router-dom";
import NavigationBar from "../components/navigationBar/navBar.tsx";
import FooterBar from "./footerBar.tsx";

export const Layout = () => {
  return (
    <div className="theme-dark">
      <div className="navbar-container">
        <NavigationBar />
      </div>
      <div className="outlet-container">
        <Outlet />
        <FooterBar />
      </div>
    </div>
  );
};
