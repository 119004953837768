import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addNewAssetAsync,
  AssetItem,
  AssetSearchResponse,
  ControllerPort,
  ControllerType,
  editAssetAsync,
  fetchAssetControllerPortDataAsync,
  fetchAssetControllerTypeDataAsync,
  fetchAssetDataAsync,
  fetchAssetDataByIdAsync,
  searchAssetByNameAsync,
} from "./assetService.tsx";
import { CustomerItem } from "../customerDetails/customerService.ts";

interface AssetState {
  asset: AssetItem[];
  customer: CustomerItem[];
  searchResults: AssetSearchResponse[];
  assetToEdit: AssetItem | null;
  port: ControllerPort[];
  type: ControllerType[];
  status: "idle" | "loading" | "succeeded" | "failed";
  assetLoading: boolean;
  assetSearchLoading: boolean;
  assetSaveLoading: boolean;
  assetDeleteLoading: boolean;
  error: string | null;
}

const initialState: AssetState = {
  asset: [],
  searchResults: [],
  assetToEdit: null,
  customer: [],
  port: [],
  type: [],
  status: "idle",
  assetLoading: false,
  assetSearchLoading: false,
  assetSaveLoading: false,
  assetDeleteLoading: false,
  error: null,
};

// Async thunk for loading assets
export const loadAsset = createAsyncThunk(
  "customer/fetchAssetDataAsync",
  async () => {
    const response = await fetchAssetDataAsync();
    return response;
  }
);

// Async thunk for fetching a asset by ID
export const fetchAssetById = createAsyncThunk(
  "customer/fetchAssetDataByIdAsync",
  async (id: string) => {
    const response = await fetchAssetDataByIdAsync(id);
    return response;
  }
);

// Async thunk for searching asset by name
export const searchAssetByName = createAsyncThunk(
  "customer/searchAssetByNameAsync",
  async (name: string) => {
    const response = await searchAssetByNameAsync(name);
    return response;
  }
);

// Async thunk for loading controller port
export const loadAssetControllerPort = createAsyncThunk(
  "customer/fetchAssetControllerPortDataAsync",
  async () => {
    const response = await fetchAssetControllerPortDataAsync();
    return response;
  }
);

// Async thunk for loading controller type
export const loadAssetControllerType = createAsyncThunk(
  "customer/fetchAssetControllerTypeDataAsync",
  async () => {
    const response = await fetchAssetControllerTypeDataAsync();
    return response;
  }
);

export const addAsset = createAsyncThunk(
  "users/addNewUserAsync",
  async (newAsset: AssetItem) => {
    const response = await addNewAssetAsync(newAsset);
    return response;
  }
);

// Async thunk for editing asset
export const editAsset = createAsyncThunk(
  "users/editAssetAsync",
  async (payload: { assetId: string; updatedAsset: AssetItem }, thunkAPI) => {
    const { assetId, updatedAsset } = payload;
    try {
      const finalupdatedUser = {
        ...updatedAsset,
      };

      const response = await editAssetAsync(assetId, finalupdatedUser);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "customer/deleteAssetAsync",
  async (assetId: string, thunkAPI) => {
    try {
      const asset = await fetchAssetDataByIdAsync(assetId);
      const updatedAsset = {
        ...asset,
        isEnabled: false,
        cnX_WellId: asset?.cnX_WellId ?? null,
        lastModifiedDate: asset?.lastModifiedDate
          ? asset.lastModifiedDate
          : new Date().toISOString(),
        poctype: {
          ...asset?.pocType,
          description: asset?.pocType?.description ?? "",
        },
      };
      const response = await editAssetAsync(assetId, updatedAsset);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const AssetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    resetAssetToEdit(state) {
      state.assetToEdit = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAsset.pending, (state) => {
        state.assetLoading = true;
      })
      .addCase(loadAsset.fulfilled, (state, action) => {
        state.assetLoading = false;
        state.asset = action.payload;
      })
      .addCase(loadAsset.rejected, (state, action) => {
        state.assetLoading = false;
        state.error = action.error.message || "Failed to load Asset";
      })
      .addCase(fetchAssetById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssetById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assetToEdit = action.payload;
      })
      .addCase(fetchAssetById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "Failed to fetch asset by ID";
      })
      .addCase(searchAssetByName.pending, (state) => {
        state.assetSearchLoading = true;
      })
      .addCase(searchAssetByName.fulfilled, (state, action) => {
        state.assetSearchLoading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchAssetByName.rejected, (state, action) => {
        state.assetSearchLoading = false;
        state.error = action.error.message || "Failed to search Customer";
      })
      .addCase(addAsset.pending, (state) => {
        state.status = "loading";
        state.assetSaveLoading = true;
      })
      .addCase(addAsset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assetSaveLoading = false;
        state.asset.push(action.payload as AssetItem);
      })
      .addCase(addAsset.rejected, (state, action) => {
        state.status = "failed";
        state.assetSaveLoading = false;
        state.error = action.error.message || "Failed to add asset";
      })
      .addCase(editAsset.pending, (state) => {
        state.status = "loading";
        state.assetSaveLoading = true;
      })
      .addCase(editAsset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assetSaveLoading = false;
        if (action.payload) {
          const updatedAsset = action.payload;
          const index = state.asset.findIndex(
            (user) => user.id === updatedAsset.id
          );
          if (index !== -1) {
            state.asset[index] = updatedAsset;
          } else {
            state.asset.push(updatedAsset);
          }
        }
      })
      .addCase(editAsset.rejected, (state, action) => {
        state.status = "failed";
        state.assetSaveLoading = false;
        state.error = action.error.message || "Failed to edit asset";
      })
      .addCase(deleteAsset.pending, (state) => {
        state.status = "loading";
        state.assetDeleteLoading = true;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assetDeleteLoading = false;
        if (action.payload) {
          const updatedAsset = action.payload;
          const index = state.asset.findIndex(
            (asset) => asset.id === updatedAsset.id
          );
          if (index !== -1) {
            state.asset[index] = updatedAsset;
          }
        }
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.status = "failed";
        state.assetDeleteLoading = false;
        state.error = action.error.message || "Failed to delete asset";
      })
      .addCase(loadAssetControllerPort.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAssetControllerPort.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.port = action.payload;
      })
      .addCase(loadAssetControllerPort.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to load Controller Ports";
      })
      .addCase(loadAssetControllerType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAssetControllerType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.type = action.payload;
      })
      .addCase(loadAssetControllerType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to load Controller Types";
      });
  },
});

export const { resetAssetToEdit } = AssetSlice.actions;

export default AssetSlice.reducer;
