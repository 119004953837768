export const setFavicon = () => {
    const favicon: any = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      return;
    }
  
    favicon.href = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'connexia-logo-dark.svg'
      : 'connexia-logo-light.svg';
  };
  