import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AssetDetails from "./components/assetDetails/assetDetails.tsx";
import CustomerDetails from "./components/customerDetails/customerDetails.tsx";
import { Layout } from "./layout/pageLayout.tsx";
import { IndexPage } from "./layout/indexPage.tsx";
import UserDetails from "./components/userDetails/userDetails.tsx";
import { setFavicon } from "./utilities/setFavicon.tsx";

const Login = lazy(() => import('./features/common/login/Login.tsx'));
const AuthComponent = lazy(() => import('./features/common/AuthComponent.tsx'));

const App: React.FC = () => {
  const applyTheme = () => {
    setFavicon();
  };

  applyTheme();

  return (
    <Router>
      <Routes>
      <Route path='/login' element={<Login />} />
      <Route path="/" element={<AuthComponent component={Layout} />}>
        <Route index element={<AuthComponent component={IndexPage} />} />
        <Route path="asset-details" element={<AuthComponent component={AssetDetails} />} />
        <Route path="user-details" element={<AuthComponent component={UserDetails} />} />
        <Route path="customer-details" element={<AuthComponent component={CustomerDetails} />} />
      </Route>
      </Routes>
    </Router>
  );
};

export default App;
