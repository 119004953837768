import React from "react";
import "./navBar.scss";
import ConnexiaLogo from "../../images/connexia-logo.svg";
import { ReactComponent as AssetListIcon } from "../../images/asset-icon.svg";
import { ReactComponent as UserListIcon } from "../../images/user-icon.svg";
import { ReactComponent as CustomerListIcon } from "../../images/customer-icon.svg";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "../../common/tooltip/tooltip.tsx";
import RouteConstant from "../../constants/routes.ts";
import { ReactComponent as CollapseIcon } from '../../images/log-out-02.svg';
import AuthService from '../../services/authentication/auth.service.ts';
import { useEffect } from 'react';

const NavigationBar: React.FC = () => {
  useEffect(() => {
    const timerId = setInterval(() => {
      console.log('Refresh token called');
      if (!AuthService.refreshTokenFromServer()) {
        AuthService.logout();
        window.location.replace(
          process.env.REACT_APP_CONNEXIA_DEVELOPMENT_URL +
            '/Account/Login?app=' +
            process.env.REACT_APP_DEVELOPMENT_WEB_APP_BASE_URL,
        );
      }
    }, 900000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const handleLogoutClick = () => {
    AuthService.logout();
  };

  const location = useLocation();
  return (
    <div className="navigation-bar-container">
      <div className="navigation-bar-top-container">
        <div className="navigation-bar-navigation-section-container">
          <Link to="/" className="navigation-bar-logo-image-container">
            <img
              id="xspoc-logo-image"
              src={ConnexiaLogo}
              alt="XSPOC logo"
              className="navigation-bar-logo-image"
            />
          </Link>
          <div className="navigation-bar-buttons-container">
            <Tooltip content="Asset List" direction="right">
              <Link
                to={RouteConstant.assetDetails}
                className={`navigation-bar-navigation-image-container ${
                  location.pathname === RouteConstant.assetDetails
                    ? "active-link"
                    : ""
                }`}
              >
                <AssetListIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>
            </Tooltip>
            <Tooltip content="User List" direction="right">
              <Link
                to={RouteConstant.userDetails}
                className={`navigation-bar-navigation-image-container ${
                  location.pathname === RouteConstant.userDetails
                    ? "active-link"
                    : ""
                }`}
              >
                <UserListIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>
            </Tooltip>
            <Tooltip content="Customer List" direction="right">
              <Link
                to={RouteConstant.customerDetails}
                className={`navigation-bar-navigation-image-container ${
                  location.pathname === RouteConstant.customerDetails
                    ? "active-link"
                    : ""
                }`}
              >
                <CustomerListIcon width="24" height="24" stroke="#F4FBFC" />
              </Link>
            </Tooltip>
          </div>
        </div>
        <div className='navigation-bar-slide-out-section-container' style={{ marginTop: "410%" }}>
          {AuthService.isUserLoggedIn() && (
            <Tooltip content='Logout' direction='right'>
              <div
                data-testid='collapse-icon'
                className={`navigation-bar-navigation-image-container`}
                onClick={handleLogoutClick}
              >
                <CollapseIcon width='24' height='24' stroke='#F4FBFC' />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
