import axios from "axios";
import {
  getAPIBaseURL,
  getXSPOCAPIBaseURL,
} from "../utilities/baseUrlutility.tsx";
import TokenService from "./authentication/token.service.ts";

const baseUrl = getAPIBaseURL();
const xspocBaseUrl = getXSPOCAPIBaseURL();

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "xspoc-subscription-key": "9407a2e738e040f692418c2d826420ca",
  },
});

const xspocApiInstance = axios.create({
  baseURL: xspocBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    handelUnauthorized(error);

    return Promise.reject(error);
  },
);

const handelUnauthorized = (error: any) => {
  if (error.response.status === 401) {
    TokenService.removeUser();

    if (window.location.pathname !== '/login') {
      window.location.reload();
    }
  }
};


export default instance;
export { xspocApiInstance };
