import React, { useEffect, useState, useRef } from "react";
import "./dropdown.scss";
import { useLocation } from "react-router-dom";
import { ReactComponent as AlertIcon } from "../../images/alert-circle.svg";
import Tooltip from "../tooltip/tooltip.tsx";
import Loader from "../../common/loader/loader.tsx";

export interface UserSearchDropdownProps {
  items: UserSearchDropdownItem[];
  defaultOption?: string;
  inputValue: string;
  onChange?: (item: UserSearchDropdownItem) => void;
  onInputChange?: (inputValue: string) => void;
  disabled?: boolean;
  error?: boolean;
  tooltipMessage?: string;
  isLoading?: boolean;
}

export interface UserSearchDropdownItem {
  name: string;
  displayName: string;
  id: string;
  cnX_SiteId?: string;
  envWellID?: string;
  apI_UWI?: string;
  sso?: boolean;
  passwordHash?: string | null;
  isSelected?: boolean;
}

const UserSearchDropdown: React.FC<UserSearchDropdownProps> = (props) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(props.defaultOption || "");
  const [visibleItems, setVisibleItems] = useState<UserSearchDropdownItem[]>(
    []
  );
  const [page, setPage] = useState(1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const itemsPerPage = 12;

  useEffect(() => {
    setSelected(props.defaultOption || "");
  }, [props.defaultOption, props.items, location.pathname]);

  useEffect(() => {
    const sortedItems = [...props.items]
      .map((item) => ({
        ...item,
        displayName: item.apI_UWI
          ? `${item.name} (${item.apI_UWI})`
          : item.name,
      }))
      .sort((a, b) => {
        const nameA = a.displayName || "";
        const nameB = b.displayName || "";
        return nameA.localeCompare(nameB, undefined, { numeric: true });
      });
    setVisibleItems(sortedItems.slice(0, itemsPerPage));
    setPage(1);
  }, [props.items]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemChange = (item: UserSearchDropdownItem) => {
    setSelected(item.displayName);
    setIsActive(false);
    if (props.onChange) {
      props.onChange({ ...item, name: item.name });
    }
  };

  useEffect(() => {
    if (selectedRef.current && isActive) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selected, isActive]);

  const loadMoreItems = () => {
    const nextPage = page + 1;
    const sortedItems = [...props.items].sort((a, b) =>
      (a.name || "").localeCompare(b.name || "", undefined, { numeric: true })
    );
    setVisibleItems(sortedItems.slice(0, nextPage * itemsPerPage));
    setPage(nextPage);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop === clientHeight &&
      visibleItems.length < props.items.length
    ) {
      loadMoreItems();
    }
  };

  return (
    <div ref={dropdownRef} className="dropdown">
      <div className="alert-icon-container">
        <input
          type="text"
          value={props.inputValue}
          onClick={(e) => {
            e.stopPropagation();
            setIsActive(true);
          }}
          onChange={(e) => {
            if (props.onInputChange) {
              props.onInputChange(e.target.value);
            }
          }}
          className="dropdown-input"
          disabled={props.disabled}
        />
        {props.error && (
          <span className="error-icon">
            <Tooltip content={props.tooltipMessage} direction="top">
              <AlertIcon />
            </Tooltip>
          </span>
        )}
      </div>
      <div
        className="dropdown-content"
        style={{ display: isActive ? "block" : "none", width: "90%" }}
        onScroll={handleScroll}
      >
        {props.isLoading ? (
          <Loader />
        ) : (
          visibleItems.map((item, index) => (
            <div
              key={`${item.id}-${index}`}
              onClick={() => handleItemChange(item)}
              className={`item ${
                selected === item.displayName ? "item-selected" : ""
              }`}
              ref={selected === item.displayName ? selectedRef : null}
              data-testid={item.name}
            >
              {item.displayName}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserSearchDropdown;
